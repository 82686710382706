import createEndpoint from "../../../images/screens/JITO-ADD-ENDPOINT.jpg";
import enable from "../../../images/screens/enable-jito.jpg";
import apikey from "../../../images/screens/JITO-API-KEY.jpg";
const HowToSection = () => {
  return (
    <section className="max-w-7xl mx-auto py-12 px-6">
      <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
        How to Integrate with Jito and Solana
      </h2>
      
      <div className="space-y-10">
        <div className="flex items-center space-x-6">
          <div className="w-1/2">
            <img src={createEndpoint} alt="Add Jito Endpoints" className="rounded-lg shadow-lg w-full h-auto"/>
          </div>
          <div className="w-2/3">
            <h3 className="text-xl font-semibold text-gray-800">1. Add Jito Endpoints</h3>
            <p className="text-gray-600">
              To start, you need to add multiple Jito API endpoints. Ensure that your endpoint URLs are correctly configured to connect to Jito.
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-6">
            <div className="w-1/2"> {/* Increased width to 1/2 */}
                <img src={enable} alt="Create API Key with Jito enabled" className="rounded-lg shadow-lg w-full h-auto" />
            </div>
            <div className="w-2/3">
                <h3 className="text-xl font-semibold text-gray-800">2. Create an API Key with Jito Enabled</h3>
                <p className="text-gray-600">
                    Next, create an API key for Solana. Select Enable Jito for the API key.
                </p>
            </div>
        </div>

        <div className="flex items-center space-x-6">
          <div className="w-1/2">
            <img src={apikey} alt="Use the Backpack Endpoint" className="rounded-lg shadow-lg w-full h-auto" />
          </div>
          <div className="w-2/3">
            <h3 className="text-xl font-semibold text-gray-800">3. Use the Backpack Endpoint and API Key</h3>
            <p className="text-gray-600">
              Finally, use the Backpack endpoint along with the generated API key to make calls to both Jito and Solana, ensuring seamless integration.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToSection;
