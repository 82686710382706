import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SIGNUPICON from "images/icons/sign-up.png";
import DASHBOARDICON from "images/icons/dashboard.png"
import JITO from "images/logo/jito.png";
export default function Hero() {
  const {isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="bg-white dark:bg-neutral-900">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-20"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#77B3DF] to-[#3BA5F3] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <header className="text-center max-w-2xl mx-auto my-20">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <img
              src={JITO}
              alt={"Jito API Load Balancer"}
              className="rounded w-24 h-24 align-left"
            />
          </div>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Seamless Load Balancing for Jito APIs
          </h2>
          <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
            Leverage Multiple Jito endpoints via a single RPC Endpoint. Interact
            seamlessly with both Solana and Jito through a single, unified RPC
            endpoint—no need to switch endpoints.
          </span>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <ButtonPrimary
              onClick={() => {
                !isAuthenticated
                  ? loginWithRedirect()
                  : (document.location.href = "/dashboard");
              }}
              style={{
                backgroundColor: "#3BA5F3",
                justifyContent: "center",
                alignItems: "center",
              }}
              className=""
            >
              <span role="img" aria-label="quicknode">
                <img
                  style={{ marginRight: "5px" }}
                  src={!isAuthenticated ? SIGNUPICON : DASHBOARDICON}
                  alt={
                    !isAuthenticated
                      ? "Get Started Now"
                      : "Go To Backpac Dashboard"
                  }
                  className="object-cover object-center rounded w-6 h-6"
                />
              </span>
              {isAuthenticated ? "Dashboard" : "Get Started Now"}
            </ButtonPrimary>
            <a
              href="https://docs.backpac.xyz/jito/overview.html"
              target="_new"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
            >
              Learn More <span aria-hidden="true">→</span>
            </a>
          </div>
        </header>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#77B3DF] to-[#3BA5F3] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
}
