//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Providers from "containers/PageHome/sections/Providers";
import Hero from "./sections/Hero";
import Enterprise from "containers/PageHome/sections/Enterprise";
import Benefits from "./sections/Benefits";
import RPCProviders from "shared/RPCProviders/RPCProviders";
import SolanaRPC from "./sections/SolanaRPC";
const Solana = () => {
  useEffect(() => {


  }, []);

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/blockchain/solana",
    "name": "Solana Blockchain Data Solutions | Backpac",
    "description": "Explore Backpac's scalable Solana blockchain data solutions. Simplify data access, optimize costs, and enhance Solana blockchain capabilities for your business.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Solana",
          "item": "https://www.backpac.xyz/blockchain/solana"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Solana Blockchain Data Solutions | Backpac",
      "about": "Scalable blockchain data access solutions for the Solana blockchain.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/static/media/Providers2.c8e6d500d388ffc18ff2.png",
        "caption": "Solana Blockchain Solutions by Backpac"
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>
          Backpac for Solana - Simplify Blockchain Data Access
        </title>
        <meta
          name="description"
          content={
            "Backpac empowers developers and businesses on Solana with seamless, scalable data access solutions. Optimize your blockchain projects with ease. Learn more!"
          }
        />
        <meta name="keywords" content="Solana blockchain, Solana data access, Scalable blockchain solutions, Solana developers, Solana API, Blockchain infrastructure, Backpac for Solana, Mainnet, Testnet, Devnet, Sepolia" />

        <meta
          name="twitter:image:alt"
          content="Backpac for Solana - Simplify Blockchain Data Access"
        />
        <meta
          property="og:image:alt"
          content="Backpac for Solana - Simplify Blockchain Data Access"
        />
        <meta name="twitter:title" content="Backpac for Solana - Simplify Blockchain Data Access" />
        <meta name="twitter:description" content="Discover how Backpac simplifies data access for Solana blockchain projects. Streamline development and scale effortlessly. Learn more!" />
        <meta property="og:title" content="Backpac for Solana - Simplify Blockchain Data Access" />
        <meta property="og:description" content="Leverage Backpac's solutions to scale your Solana blockchain data access. Perfect for developers and businesses seeking efficiency and scalability." />
        <meta property="og:url" content="https://www.backpac.xyz/blockchain/solana" />
        <link rel="canonical" href="https://www.backpac.xyz/blockchain/solana" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <Hero />
      <Benefits/>
      <RPCProviders blockchain={"solana"} />
      <SolanaRPC />
      <Providers />
      <Enterprise />
    </>
  );
};
export default Solana;
