import { ServerIcon, CheckIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import JITO from "images/screens/jito-load-balancer.png";
const features = [
  {
    name: 'Simplified Integration',
    description:
      "Seamlessly interact with Solana and Jito through your existing Backpac RPC endpoint, making setup and operation effortless.",
    icon: ClipboardIcon,
  },
  {
    name: 'Scalable Architecture',
    description: 'Backpac’s advanced architecture introduces dynamic failover capabilities, ensuring uninterrupted RPC request fulfillment by instantly switching to an alternative provider during node issues.',
    icon: ServerIcon,
  },
  {
    name: 'Reliable Failover',
    description: 'Enjoy guaranteed uptime with intelligent failover and retry mechanisms, keeping your transactions flowing smoothly no matter the circumstances.',
    icon: CheckIcon,
  },
]

export default function Benefits() {
  return (
    <div className="overflow-hidden bg-white dark:bg-neutral-900 py-8 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600 dark:text-neutral-400">
              Effortless Multi-Endpoint Access
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Why Choose Backpac's Load Balancer for Jito APIs?
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
                Backpac's Load Balancer for Jito empowers you to leverage
                multiple Jito endpoints via a single RPC endpoint, ensuring
                unmatched speed, reliability, and scalability for transaction sending.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-neutral-400 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={JITO}
            alt="Jito API Load Balancer"
            className="w-[30rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem] md:-ml-4 lg:-ml-0"
            width={935}
            height={717}
          />
        </div>
      </div>
    </div>
  );
}
