import { User } from "interfaces/Auth0";
import backpacIcon from "images/backpac.svg";
interface ConfirmEmailProps {
  user: User | undefined;
}
const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ user }) => {
  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-white dark:bg-neutral-900">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <a href={`/`}>
          <img
            className="mx-auto h-20 w-auto"
            src={backpacIcon}
            alt="Backpac Inc"
            style={{marginBottom: '2rem'}}
          />
        </a>
      </div>
      <div className="max-w-xl px-5 text-center">
        <h2 className="mb-2 text-[42px] font-bold text-zinc-800 dark:text-white">
          Check your inbox
        </h2>
        <p className="mb-2 text-lg text-zinc-500 dark:bg-neutral-900">
          We are glad, that you’re with us ? We’ve sent you a verification link
          to the email address{" "}
          <span className="font-medium text-primary-500 dark:bg-neutral-900">
            {user?.email}
          </span>
          .
        </p>
        <p className="mb-2 text-lg text-zinc-500 dark:bg-neutral-900">
          If you've received a welcome email from us, you can successfully login to the dashboard.
        </p>
        <p className="mb-2 text-lg text-zinc-500 dark:bg-neutral-900">
          Please follow the instructions in the email to verify your Email
          Address.
        </p>
        <a
          href="/dashboard"
          className="mt-3 inline-block w-96 rounded px-5 py-3 font-medium text-white shadow-md shadow-indigo-500/20 disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50  dark:white"
        >
          Confirmed? Open Dashboard →
        </a>
      </div>
    </div>
  );
};
export default ConfirmEmail;
