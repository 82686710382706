import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ContactUsSocialsList from "shared/SocialsList/ContactUsSocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Slack from 'model/Slack';
import { CheckIcon, ExclamationCircleIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from 'react-toastify';
export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "Miami, FL",
  },
  {
    title: "💌 EMAIL",
    desc: "hello@backpac.xyz",
  },
  // {
  //   title: "☎ PHONE",
  //   desc: "000-123-456-7890",
  // },
];



const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  //email, name, subject, message
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => { 
  
   }, []);

  const handleSubmit = async (event: any) =>{
    event.preventDefault();
    const subject = "Beta - Contact Us"
    //console.log({subject, email, name, message});

    const slack = new Slack(undefined);

    toast.promise(
      slack.contactUs(email, name, subject, message),
      {
        pending: {
          render(){
            setLoading(true);
            return "Sending message"
          },
          icon: <PaperAirplaneIcon className="w-5 h-5"/>,
        },
        success: {
          render({data}){
            setEmail("");
            setName("");
            setMessage("");
            setLoading(false);
            return `Message received. Thank you ${name}`
          },
          // other options
          icon: <CheckIcon className="w-5 h-5" />,
        },
        error: {
          render({data}){
            // When the promise reject, data will contains the error
            return `Error: ${data}`
          },
          icon: <ExclamationCircleIcon className="w-5 h-5"/>
        },
      }
  )
  }

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contact Backpac",
    "description": "Need help or have questions about Backpac? Contact us today for support, partnerships, or more information on blockchain solutions.",
    "url": "https://www.backpac.xyz/contact",
    "image": "https://image.nft.backpac.xyz/crop.png",
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Support",
      "email": "hello@backpac.xyz",
      "areaServed": "Global",
      "availableLanguage": ["English"]
    }
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact Backpac - Get in Touch for Blockchain Solutions</title>
        <meta name="description" content="Need help or have questions about Backpac? Contact us today for support, partnerships, or more information on blockchain solutions. We're here to help!" />
        <meta name="keywords" content="Contact Backpac, Blockchain support, Backpac inquiries, Blockchain solutions help, Partnership with Backpac, Backpac customer support" />
        <meta property="og:title" content="Contact Backpac - Get in Touch for Blockchain Solutions" />
        <meta property="og:description" content="Have questions or need assistance? Contact Backpac for support, partnerships, or blockchain solution inquiries. We're here to help!" />
        <meta property="og:url" content="https://www.backpac.xyz/contact" />
        <meta name="twitter:title" content="Contact Backpac - Get in Touch for Blockchain Solutions" />
        <meta name="twitter:description" content="Reach out to Backpac for support, partnership opportunities, or blockchain solution inquiries. We're just a message away!" />
        <link rel="canonical" href="https://www.backpac.xyz/contact" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <ContactUsSocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form name="backpac-contact-us" onSubmit={handleSubmit} className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    disabled={loading}
                    required
                    onChange={ e=>{
                      setName(e.target.value)
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    disabled={loading}
                    required
                    onChange={ e=>{
                      setEmail(e.target.value)
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>
                  <Textarea required disabled={loading} onChange={ e=>{
                      setMessage(e.target.value)
                    }} className="mt-1" rows={6} />
                </label>
                <div>
                  <ButtonPrimary disabled={loading} type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default PageContact;