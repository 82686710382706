import { BoltIcon, NoSymbolIcon, ArrowPathIcon } from '@heroicons/react/24/solid';
// import ProvidersScreen from "images/screens/solana-ore-mining.png";
import OreMiners from './OreMiners';
const features = [
  {
    name: 'Avoid Rate Limits',
    description:
      'Automatically failover to alternative providers when rate limits are hit, ensuring uninterrupted transaction processing.',
    icon: NoSymbolIcon,
    iconColor: '#FF0000'
  },
  {
    name: 'RPC Request Failover Redundancy',
    description: 'In case of node provider issues, Backpac dynamically switches to a backup provider, maintaining high availability and reliability.',
    icon: ArrowPathIcon,
    iconColor: 'blue'
  },
  {
    name: 'Parallel RPC Request Processing',
    description: 'Backpac™ boosts efficiency with Parallel RPC Request Processing, allowing you to send a single RPC request to multiple endpoints simultaneously.',
    icon: BoltIcon,
    iconColor: 'orange'
  },
]

export default function Benefits() {
  return (
    <div className="overflow-hidden bg-white dark:bg-neutral-900 py-2 sm:py-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-2 gap-y-4 sm:grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600 dark:text-neutral-400">
                Land Transactions
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Solana RPC Features for Ore Miners
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-neutral-400">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac&trade;</span> optimizes your Solana mining operations by
                strategically distributing RPC requests across multiple node providers.
                This intelligent load balancing helps you avoid rate limits and increases your
                chances of successfully landing transactions.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 dark:text-neutral-400 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900 dark:text-white">
                      <feature.icon
                        className="absolute left-1 top-1 h-6 w-6"
                        aria-hidden="true"
                        color={feature.iconColor}
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="lg:pr-8 lg:pt-4">
            <OreMiners />
          </div>
        </div>

      </div>
    </div>
  );
}
