import React, { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from 'react-toastify';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Import Chart.js

export interface CostSavingsCalculatorProps {
  className?: string;
}

// Define the data structure based on the provided Excel data
interface ApiMethodData {
  method: string;
  dailyRequests: number;
  creditCost: number;
  blockchain: string;
  monthlyCreditsConsumption: number;
  overage: number;
  monthlyCost: number;
  monthlySavingsWithCaching: number;
  monthlySavings: number;
}

const ethRpcMethods = [
  {
    method: "eth_blockNumber",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_chainId",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 5 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getBalance",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getBlockByHash",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getBlockByNumber",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getBlockTransactionCountByHash",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getBlockTransactionCountByNumber",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getLogs",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 225 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getTransactionByBlockHashAndIndex",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getTransactionByBlockNumberAndIndex",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getTransactionByHash",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getTransactionCount",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getTransactionReceipt",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getUncleByBlockHashAndIndex",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getUncleByBlockNumberAndIndex",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getUncleCountByBlockHash",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_getUncleCountByBlockNumber",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "eth_protocolVersion",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost:20 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
];

const solanaRpcMethods = [
  {
    method: "getAccountInfo",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getBalance",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getBlockTime",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getConfirmedBlock",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getConfirmedTransaction",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getEpochInfo",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getRecentBlockhash",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getSupply",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 160 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getTokenAccountBalance",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
  {
    method: "getTokenSupply",
    providers: [
      { name: "Chainstack", creditCost: 1 },
      { name: "Alchemy", creditCost: 1 },
      { name: "Quicknode", creditCost: 30 },
      { name: "Infura", creditCost: 2 },
      { name: "Ankr", creditCost: 1 },
    ],
  },
];

const rpcProviders = [
  {
    name: "Chainstack",
    plans: [
      {
        name: "Growth plan",
        monthlyPrice: 49,
        credits: 20000000,
        additionalCreditCost: 0.1,
        pricePerMillionRequest: 2.45,
      },
      {
        name: "Business plan",
        monthlyPrice: 349,
        credits: 140000000,
        additionalCreditCost: 0.08,
        pricePerMillionRequest: 2.49,
      },
    ],
  },
  {
    name: "Alchemy",
    plans: [
      {
        name: "Growth plan",
        monthlyPrice: 49,
        credits: 40000000,
        additionalCreditCost: 1.20,
        pricePerMillionRequest: 2.94,
      }
    ],
  },
  {
    name: "Quicknode",
    plans: [
      {
        name: "Starter plan",
        monthlyPrice: 10,
        credits: 25000000,
        additionalCreditCost: 0.0000004,
        pricePerMillionRequest: 0.40,
      },
      {
        name: "Build plan",
        monthlyPrice: 39,
        credits: 75000000,
        additionalCreditCost: 0.00000035,
        pricePerMillionRequest: 0.35,
      },
      {
        name: "Scale plan",
        monthlyPrice: 199,
        credits: 300000000,
        additionalCreditCost: 0.000000325,
        pricePerMillionRequest: 0.325,
      },
    ],
  },
  {
    name: "Infura",
    plans: [
      {
        name: "Developer plan",
        monthlyPrice: 50,
        credits: 450000000,
        additionalCreditCost: 0.00002,
        pricePerMillionRequest: 8.30,
      },
      {
        name: "Team plan",
        monthlyPrice: 225,
        credits: 2250000000,
        additionalCreditCost: 0.00001,
        pricePerMillionRequest: 7.50,
      },
    ],
  },
  {
    name: "Ankr",
    plans: [
      {
        name: "Pay as you go plan",
        monthlyPrice: 10,
        credits: 100000000,
        additionalCreditCost: 0.02,
        pricePerMillionRequest: 0.10,
      },
      {
        name: "Deal plan",
        monthlyPrice: 500,
        credits: 6000000000,
        additionalCreditCost: 0.02,
        pricePerMillionRequest: 0.10,
      },
    ],
  },
];


const formatAsMoney = (value: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

const formatAsNumber = (value: number, decimalPlaces: number = 0): string =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);


const CostSavingsCalculator: FC<CostSavingsCalculatorProps> = ({ className = "" }) => {
  const [methods, setMethods] = useState<ApiMethodData[]>([]);
  const [newBlockchain, setNewBlockchain] = useState<string>("Ethereum");
  const [newMethod, setNewMethod] = useState<string>("");
  const [newDailyRequests, setNewDailyRequests] = useState<number>(0);
  const [newCreditCost, setNewCreditCost] = useState<number>(0);
  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [monthlyCredits, setMonthlyCredits] = useState<number>(0);
  const [totalMonthlyCost, setTotalMonthlyCost] = useState<number>(0);
  const [totalMonthlyCostWithSavings, setTotalMonthlyCostWithSavings] = useState<number>(0);
  const [totalMonthlySavings, setTotalMonthlySavings] = useState<number>(0);

  useEffect(() => {
    if (selectedPlan) {
      const { updatedMethods, totalMonthlyWithoutSavings, totalMonthlyWithSavings, totalMonthlyCostSavings } = calculateSummary(methods);
      setMethods(updatedMethods);
      setMonthlyCredits(updatedMethods.reduce((acc, item) => acc + item.monthlyCreditsConsumption, 0));
      setTotalMonthlyCost(totalMonthlyWithoutSavings);
      setTotalMonthlyCostWithSavings(totalMonthlyWithSavings);
      setTotalMonthlySavings(totalMonthlyCostSavings);
    }
  }, [selectedPlan]);

  const handleProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const provider = rpcProviders.find(p => p.name === e.target.value);
    setSelectedProvider(e.target.value);
    setSelectedPlan(provider ? provider.plans[0] : null);

    // Recalculate credit costs for existing methods
    const updatedMethods = methods.map(method => {
      const rpcMethod = (newBlockchain === "Ethereum" ? ethRpcMethods : solanaRpcMethods).find(m => m.method === method.method);
      if (rpcMethod) {
        const provider = rpcMethod.providers.find(p => p.name === e.target.value);
        return {
          ...method,
          creditCost: provider ? provider.creditCost : method.creditCost,
        };
      }
      return method;
    });
    setMethods(updatedMethods);
  };

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const plan = rpcProviders.find(p => p.name === selectedProvider)?.plans.find(p => p.name === e.target.value);
    setSelectedPlan(plan || null);
  };

  const handleBlockchainChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewBlockchain(e.target.value);
    setNewMethod("");
    setNewCreditCost(0);
  };

  const handleMethodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const method = (newBlockchain === "Ethereum" ? ethRpcMethods : solanaRpcMethods).find(m => m.method === e.target.value);
    setNewMethod(e.target.value);
    if (method && selectedProvider) {
      const provider = method.providers.find(p => p.name === selectedProvider);
      setNewCreditCost(provider ? provider.creditCost : 0);
    }
  };

  const handleReset = () => {
    if (methods.length > 0) {
      setMethods([]);
      setNewMethod("");
      setMonthlyCredits(0);
      setTotalMonthlyCost(0);
      setTotalMonthlyCostWithSavings(0);
      setTotalMonthlySavings(0);
      toast.success(`Calculator reset successfully`);
    } else {
      toast.error("Please select a provider and plan before adding a method.");
    }
  };

  const handleAddMethod = () => {
    if (newMethod && newDailyRequests > 0 && newCreditCost > 0 && selectedProvider && selectedPlan) {
      const updatedMethods = [
        ...methods,
        {
          method: newMethod,
          dailyRequests: newDailyRequests,
          creditCost: newCreditCost,
          blockchain: newBlockchain,
          monthlyCreditsConsumption: 0,
          overage: 0,
          monthlyCost: 0,
          monthlySavingsWithCaching: 0,
          monthlySavings: 0,
        },
      ];
      const { updatedMethods: newMethods } = calculateSummary(updatedMethods);
      setMethods(newMethods);
      setNewMethod("");
      setNewDailyRequests(0);
      setNewCreditCost(0);
      toast.success(`Method ${newMethod} added successfully`);
    } else {
      toast.error("Please select a provider and plan before adding a method.");
    }
  };

  const handleDeleteMethod = (index: number) => {
    const updatedMethods = methods.filter((_, i) => i !== index);
    setMethods(updatedMethods);
  };

  const calculateSummary = (methodsToCalculate = methods) => {
    const updatedMethods = methodsToCalculate.map(method => {
      const monthlyCreditsConsumption = method.dailyRequests * method.creditCost * 30;
      const overage = (monthlyCredits + monthlyCreditsConsumption) > selectedPlan.credits ? (monthlyCredits + monthlyCreditsConsumption) - selectedPlan.credits : 0;
      const monthlyCost = overage > 0 ? selectedPlan.pricePerMillionRequest * (overage / 1000000) : 0;
      const monthlySavingsWithCaching = monthlyCost * 0.4; // Assuming 40% savings
      const monthlySavings = monthlyCost - monthlySavingsWithCaching;

      return {
        ...method,
        monthlyCreditsConsumption,
        overage,
        monthlyCost,
        monthlySavingsWithCaching,
        monthlySavings,
      };
    });

    const summary = updatedMethods.map((method) => {
      const monthlyCalls = method.dailyRequests * 30;
      const dailyCredits = method.dailyRequests * method.creditCost;
      const methodMonthlyCredits = dailyCredits * 30;

      const dailyCostWithoutSavings = selectedPlan ? method.monthlyCost / 30 : 0;
      const dailyCostWithSavings = selectedPlan ? dailyCostWithoutSavings * 0.6 : 0; // Assuming 40% savings

      const dailyCreditsSavings = dailyCredits * 0.4; // Assuming 40% savings
      const monthlyCreditsSavings = dailyCreditsSavings * 30;

      const dailyCostSavings = dailyCostWithoutSavings - dailyCostWithSavings;

      const monthlyCostWithoutSavings = dailyCostWithoutSavings * 30;
      const monthlyCostWithSavings = dailyCostWithSavings * 30;

      return {
        monthlySavingsWithCaching: method.monthlySavingsWithCaching,
        method: method.method,
        monthlyCalls,
        monthlyCost: method.monthlyCost,
        creditCost: method.creditCost,
        dailyCredits,
        dailyCreditsSavings,
        monthlyCredits: methodMonthlyCredits,
        monthlyCreditsSavings,
        dailyCostSavings,
        dailyCostWithoutSavings,
        dailyCostWithSavings,
        monthlyCostWithoutSavings,
        monthlyCostWithSavings,
      };
    });

    // Price $ calculation without savings
    let totalMonthlyWithoutSavings = 0;
    if (selectedPlan) {
      if (monthlyCredits < selectedPlan.credits) {
        totalMonthlyWithoutSavings = selectedPlan.monthlyPrice;
      } else {
        totalMonthlyWithoutSavings = selectedPlan.monthlyPrice + summary.reduce((acc, item) => acc + item.monthlyCost, 0);
      }
    }

    // Total monthly credits calculation with savings
    const totalMonthlyWithSavings = summary.reduce((acc, item) => acc + item.monthlyCostWithSavings, 0);
    // Price difference between totalMonthlyWithoutSavings and totalMonthlyWithSavings
    const totalMonthlyCostSavings = totalMonthlyWithoutSavings - totalMonthlyWithSavings;

    return { updatedMethods, summary, totalMonthlyWithoutSavings, totalMonthlyWithSavings, totalMonthlyCostSavings };
  };

  const { summary } = calculateSummary();

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "CostSavingsCalculator",
    "name": "Blockchain RPC Cost Savings Calculator",
    "description": "Calculate your cost savings with Backpac's Smart Caching feature. Reduce unnecessary RPC calls, save on API credits and compute unit costs.",
    "url": "https://www.backpac.xyz/cost-savings-calculator",
    "image": "https://image.nft.backpac.xyz/crop.png",
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz"
    },
  };

  const chartData = {
    labels: ['Without Caching', 'With Caching'],
    datasets: [
      {
        label: 'Monthly Cost',
        data: [totalMonthlyCost, totalMonthlyCostWithSavings],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
      {
        label: 'Cost Savings',
        data: [0, totalMonthlySavings],
        backgroundColor: ['#FFCE56'],
      },
    ],
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Cost Savings Calculator</title>
        <meta name="description" content="Calculate your cost savings with Backpac's Smart Caching feature. Reduce unnecessary RPC calls, save on API credits and compute unit costs." />
        <meta name="keywords" content="Cost Savings Calculator, Blockchain RPC, Smart Caching, API credits, Compute unit costs, Blockchain solutions" />
        <meta property="og:title" content="Blockchain RPC Cost Savings Calculator" />
        <meta property="og:description" content="Calculate your cost savings with Backpac's Smart Caching feature. Reduce unnecessary RPC calls, save on API credits and compute unit costs." />
        <meta property="og:url" content="https://www.backpac.xyz/cost-savings-calculator" />
        <meta name="twitter:title" content="Blockchain RPC Cost Savings Calculator" />
        <meta name="twitter:description" content="Calculate your cost savings with Backpac's Smart Caching feature. Reduce unnecessary RPC calls, save on API credits and compute unit costs." />
        <link rel="canonical" href="https://www.backpac.xyz/cost-savings-calculator" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <div className="mb-24 lg:mb-32">

        <div className="container max-w-7xl mx-auto">
          {/* Hero Text Section */}
          <div className="p-5 max-w-4xl mx-auto ">
            <h1 className="text-3xl font-bold mb-4">Cost-Efficient Blockchain Access with Smart Caching</h1>
            <p className="text-md mb-2">
              Backpac’s Smart Caching feature reduces unnecessary RPC calls to third-party providers, helping you save on API credits and CU (compute unit) costs. By caching blockchain data based on the block time of the network, Backpac ensures efficient access to blockchain data without compromising accuracy. This feature is seamlessly integrated into Backpac’s API Key system, providing control over caching behavior for your applications.
            </p>
          </div>

                    {/* Start section */}
                    <div className="p-5 max-w-4xl mx-auto rounded-lg shadow-md mb-10" style={{ marginTop: '15px'}}>
          <h2 className="my-15 flex items-center text-2xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Cost Savings Calculator
        </h2>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              <div>
                <label className="block mb-1 font-medium text-sm">RPC Provider:</label>
                <select
                  value={selectedProvider}
                  onChange={handleProviderChange}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                >
                  <option value="">Select Provider</option>
                  {rpcProviders.map((provider, index) => (
                    <option key={index} value={provider.name}>{provider.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block mb-1 font-medium text-sm">Plan:</label>
                <select
                  value={selectedPlan?.name || ""}
                  onChange={handlePlanChange}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                  disabled={!selectedProvider}
                >
                  <option value="">Select Plan</option>
                  {selectedProvider && rpcProviders.find(p => p.name === selectedProvider)?.plans.map((plan, index) => (
                    <option key={index} value={plan.name}>{plan.name} {formatAsMoney(plan.monthlyPrice)}</option>
                  ))}
                </select>
              </div>
            </div>

            {selectedProvider && selectedPlan && (
              <div className="mt-4">
                <strong>Credits:</strong> {formatAsNumber((selectedPlan.credits))} &nbsp; | &nbsp;
                <strong>Credit Cost:</strong> {formatAsMoney((selectedPlan.pricePerMillionRequest * 0.1))}
              </div>
            )}

            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mt-4">
              <div>
                <label className="block mb-1 font-medium text-sm">Blockchain:</label>
                <select
                  value={newBlockchain}
                  onChange={handleBlockchainChange}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                >
                  <option value="Ethereum">Ethereum</option>
                  <option value="Solana">Solana</option>
                </select>
              </div>
              <div>
                <label className="block mb-1 font-medium text-sm">RPC Method:</label>
                <select
                  value={newMethod}
                  onChange={handleMethodChange}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                >
                  <option value="">Select Method</option>
                  {(newBlockchain === "Ethereum" ? ethRpcMethods : solanaRpcMethods).map((method, index) => (
                    <option key={index} value={method.method}>{method.method}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block mb-1 font-medium text-sm">Daily Requests:</label>
                <input
                  type="number"
                  value={newDailyRequests}
                  onChange={(e) => setNewDailyRequests(Number(e.target.value))}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-sm">Credit Cost per Request:</label>
                <input
                  type="number"
                  value={newCreditCost}
                  onChange={(e) => setNewCreditCost(Number(e.target.value))}
                  className="w-full border border-gray-300 rounded p-2 text-sm"
                  disabled
                />
              </div>
            </div>
            <button
              onClick={handleAddMethod}
              className="bg-blue-500 text-white text-sm px-3 py-2 rounded hover:bg-blue-600 mt-4"
            >
              Add Method
            </button>

            <h2 className="text-xl font-semibold mt-10 mb-4">Added Methods</h2>
            <ul className="list-disc list-inside">
              {methods.map((method, index) => (
                <li key={index}>
                  <u>{method.blockchain}</u>{': '}<b>{method.method}</b> - <u>{formatAsNumber(method.dailyRequests)}</u> request consumes <u>{formatAsNumber(method.dailyRequests * method.creditCost)}</u> credits/cu's daily
                  <button
                    onClick={() => handleDeleteMethod(index)}
                    className="ml-2 text-red-500 text-xs"
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>

            <div className="mt-8 mb-4 flex justify-right">
              <h2 className="text-l font-semibold">Total Monthly Credits/CUs:</h2>
              <p className="text-l ml-2">{formatAsNumber(monthlyCredits)}</p>
            </div>

            <h2 className="text-md font-semibold mt-8 mb-2">Monthly Summary</h2>
            <table className="table-auto w-full border-collapse text-sm">
              <thead>
              <tr className="bg-gray-100 text-center">
                  <th className="border border-gray-300 px-2 py-1" colSpan={2}></th>
                  <th className="border border-gray-300 px-2 py-1" colSpan={2}>Withing Caching</th>
                  <th className="border border-gray-300 px-2 py-1" colSpan={3}>With Caching</th>
                </tr>
                <tr className="bg-gray-100 text-center">
                  <th className="border border-gray-300 px-2 py-1">RPC Method</th>
                  <th className="border border-gray-300 px-2 py-1">Request</th>
                  <th className="border border-gray-300 px-2 py-1">Credits</th>
                  <th className="border border-gray-300 px-2 py-1">Costs</th>
                  <th className="border border-gray-300 px-2 py-1">Credits</th>
                  <th className="border border-gray-300 px-2 py-1">Cost</th>
                  <th className="border border-gray-300 px-2 py-1">Savings</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, index) => (
                  <tr key={index} className="text-center">
                    <td className="border border-gray-300 px-2 py-1 truncate">{item.method}</td>
                    <td className="border border-gray-300 px-2 py-1">{formatAsNumber(item.monthlyCalls)}</td>
                    <td className="border border-gray-300 px-2 py-1">{formatAsNumber(item.monthlyCredits)}</td>
                    <td className="border border-gray-300 px-2 py-1 text-red-500">{formatAsMoney(item.monthlyCost)}</td>
                    <td className="border border-gray-300 px-2 py-1">{formatAsNumber(item.monthlyCredits - item.monthlyCreditsSavings)}</td>
                    <td className="border border-gray-300 px-2 py-1 text-green-500">{formatAsMoney(item.monthlySavingsWithCaching)}</td>
                    <td className="border border-gray-300 px-2 py-1 text-orange-500">{formatAsMoney(item.monthlySavingsWithCaching > 0 ? item.monthlyCost - item.monthlySavingsWithCaching : 0)}</td>
                    
                  </tr>
                ))}
                  <tr className="bg-gray-500 text-center">
                    <td className="border border-gray-300 px-2 py-1" colSpan={7}></td>           
                  </tr>
                {methods.length > 0 && (
                  <tr className="text-center">
                    <td className="border border-gray-300 px-2 py-1"><button
                      onClick={handleReset}
                      className="bg-blue-500 text-white text-xs px-2 py-1 rounded hover:bg-blue-600 mt-4"
                    >
                      Reset
                    </button></td>
                    <td className="border border-gray-300 px-2 py-1" colSpan={2}><b>Totals</b></td>
                    <td className="border border-gray-300 px-2 py-1 text-red-500"><b>{formatAsMoney(totalMonthlyCost)}</b></td>
                    <td className="border border-gray-300 px-2 py-1"></td>
                    <td className="border border-gray-300 px-2 py-1 text-bold text-green-500"><b>{formatAsMoney(totalMonthlyCostWithSavings)}</b></td>
                    <td className="border border-gray-300 px-2 py-1 text-orange-500"><b>{formatAsMoney(totalMonthlySavings)}</b></td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* Chart Section */}
            <div className="mt-10">
              <h2 className="text-xl font-semibold mb-4">Cost Savings with Backpac Caching</h2>
              <Bar data={chartData} />
            </div>
            {/* End Chart Section */}
      
          </div>
          {/* End section */}

          <div className="p-5 max-w-4xl mx-auto rounded-lg shadow-md mt-10">
              <h2 className="text-2xl font-bold mb-4">How Smart Caching Works</h2>
              <ol className="list-decimal list-inside">
                <li className="mb-2">
                  <strong>Block-Time Based Caching</strong>
                  <p>Backpac uses the blockchain's block time to determine when cached data is valid and when to fetch fresh data. Cached results remain valid for 60% of the blockchain’s average block time, balancing performance and data freshness.</p>
                </li>
                <li className="mb-2">
                  <strong>Intelligent Caching Rules</strong>
                  <p>Caching is applied to read-only RPC methods (e.g., eth_call, getProgramAccounts) that don’t require real-time state changes. Write operations and critical methods are excluded to prevent stale data issues.</p>
                </li>
                <li className="mb-2">
                  <strong>Dynamic Expiry Management</strong>
                  <p>Cached data automatically expires after 60% of the block time, ensuring your application retrieves fresh data when needed.</p>
                </li>
                <li className="mb-2">
                  <strong>Third-Party Call Optimization</strong>
                  <p>By reducing repetitive calls to third-party RPC providers, Smart Caching minimizes:</p>
                  <ul className="list-disc list-inside ml-5">
                    <li>API credit usage.</li>
                    <li>Compute unit consumption.</li>
                    <li>Overall costs.</li>
                  </ul>
                </li>
              </ol>
            </div>


          {/* Blockchain Network Block Times Section */}
          <div className="p-5 max-w-4xl mx-auto rounded-lg shadow-md mb-10" style={{ marginTop: '15px'}}>
            <h2 className="text-2xl font-bold mb-4">Blockchain Network Block Times</h2>
            <p className="text-md mb-2">
              Backpac calculates caching intervals as 60% of the average block time for each blockchain network. Below are the block times for supported networks:
            </p>
            <table className="table-auto w-full border-collapse text-sm mb-4">
              <thead>
                <tr className="bg-gray-100 text-center">
                  <th className="border border-gray-300 px-2 py-1">Blockchain Network</th>
                  <th className="border border-gray-300 px-2 py-1">Average Block Time</th>
                  <th className="border border-gray-300 px-2 py-1">Cache Duration (60%)</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td className="border border-gray-300 px-2 py-1">Ethereum</td>
                  <td className="border border-gray-300 px-2 py-1">~12 seconds</td>
                  <td className="border border-gray-300 px-2 py-1">~7 seconds</td>
                </tr>
                <tr className="text-center">
                  <td className="border border-gray-300 px-2 py-1">Solana</td>
                  <td className="border border-gray-300 px-2 py-1">~0.4 seconds</td>
                  <td className="border border-gray-300 px-2 py-1">~0.24 seconds</td>
                </tr>
              </tbody>
            </table>
            <p className="text-md">
              Backpac uses these cache durations to ensure accurate and timely responses while reducing unnecessary RPC calls.
            </p>
          </div>

          {/* New Section */}
          <div className="p-5 max-w-4xl mx-auto">
            <h2 className="text-xl font-bold mb-4">Example Use Case: eth_getBalance</h2>
            <p className="text-md mb-2">
              <strong>Request:</strong> A user calls eth_getBalance to fetch an account's balance.
            </p>
            <p className="text-md mb-2">
              <strong>Smart Caching in Action:</strong>
            </p>
            <ul className="list-disc list-inside ml-5 mb-2">
              <li>Backpac checks the cache for a recent result.</li>
              <li>If the result is valid based on the block time (7 seconds for Ethereum), the cached balance is returned.</li>
              <li>If the cache has expired, Backpac fetches fresh data from the RPC provider.</li>
            </ul>
            <p className="text-md mb-2">
              <strong>Result:</strong>
            </p>
            <ul className="list-disc list-inside ml-5">
              <li>The user receives accurate balance data.</li>
              <li>API credits and CU costs are minimized by avoiding redundant provider calls.</li>
            </ul>
          </div>


        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default CostSavingsCalculator;