import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Template
import ScrollToTop from "./ScrollToTop";
import SiteHeader from "containers/SiteHeader";
import Footer from "shared/Footer/Footer";
//Authentication
import Auth0ProviderWithHistory from "../auth/auth0-provider-with-history";
//Pages
import PageLogin from "containers/PageLogin/PageLogin";
//import PageHome from "containers/PageHome/PageBuilderIOHome";
import AccountPage from "containers/AccountPage/AccountPage";
import CompanyPage from "containers/CompanyPage/CompanyPage";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageContact from "containers/PageContact/PageContact";
import PageProduct from "containers/PageProduct/PageProduct";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageSubscription from "containers/PageSubscription/PageSubscription";
import LoginDashboard from "auth/login";
import Home from "containers/PageHome/Home";
import Features from "containers/PageFeatures/Features";
import Ethereum from "containers/PageEthereum/Ethereum";
import Polygon from "containers/Blockchains/PagePolygon/Polygon";
import Solana from "containers/Blockchains/PageSolana/Solana";
import Products from "containers/PageProduct";
import Product from "containers/PageProduct/Product";
import Arweave from "containers/PermaWeb/PageArweave/Arweave";
import Terms from "containers/TermsPage/TermsOfService";
import Testimonial from "containers/TermsPage/Testimonial";
import Privacy from "containers/TermsPage/Privacy";
import ORE from "../containers/Blockchains/PageSolanaORE/ORE";
import CostSavingsCalculator from "containers/PageCostSavingsCalculator";
import PageJupiterPricing from "containers/PageSubscription/Jupiter/PagePricing";
import Jito from "containers/PageJito/Jito";
const NodeCache = require( "node-cache" );
const myCache = new NodeCache({ stdTTL: 90000, checkperiod: 95000 });
const AppRoutes: React.FC = () => {

  const checkParameter = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const av81Param = urlParams.get('av81');

    // Check if URL parameter 'av81' exists
    if (av81Param !== null) {
      console.log('URL parameter av81 exists:', true);
      return true;
    } else {
      // Check if REACT_APP_STAGE equals production
      const isProduction = process.env.REACT_APP_STAGE === 'production';
      console.log('Is production:', isProduction);
      return !isProduction;
    }
  };


  return (
    <BrowserRouter basename={"/"}>
      <Auth0ProviderWithHistory>
        <ScrollToTop />
        <SiteHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/health">
            <h2>The Dashboard is Healthy</h2>
          </Route> */}
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<PageLogin />} />
          <Route path="/signup" element={<PageSignUp />} />
          <Route path="/product" element={<PageProduct />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cost-savings-calculator" element={<CostSavingsCalculator />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/rpc-node-provider/:provider/:plan" element={<Product />} />
          <Route path="/rpc-node-provider-integrations" element={<Products />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/blog" element={<BlogPage cache={myCache}/>} />
          <Route path="/blog/:blogId" element={<BlogSingle cache={myCache}/>} />
          <Route path="/contact" element={<PageContact />} />
          <Route path="/support" element={<PageContact />} />
          <Route path="/pricing" element={<PageSubscription cache={myCache}/>} />
          <Route path="/add-on/jupiter" element={<PageJupiterPricing cache={myCache}/>} />
          <Route path="/jito-load-balancer" element={<Jito />} />
          <Route path="/dashboard" element={<LoginDashboard cache={myCache}/>} />
          <Route path="/verified" element={<Navigate to="/" replace />} />
          <Route path="/features" element={checkParameter() ? <Features /> : <Home /> } />
          <Route path="/blockchains/ethereum" element={<Navigate to="/blockchain/ethereum" replace />} />
          <Route path="/blockchain/ethereum" element={<Ethereum />} />
          <Route path="/blockchain/polygon" element={<Polygon />} />
          <Route path="/blockchain/solana" element={<Solana />} />
          <Route path="/blockchain/solana/ore-mining" element={<ORE />} />
          <Route path="/permaweb/arweave" element={<Arweave />} />
          <Route path="/blockchain/binance-smart-chain" element={<Navigate to="/" replace />} />
          <Route path="/blockchain/avalanche" element={<Navigate to="/" replace />} />
          <Route path="/blockchain/arbitrum" element={<Navigate to="/" replace />} />
          <Route path="/blockchain/optimism" element={<Navigate to="/" replace />} />
          <Route path="/blockchain/base" element={<Navigate to="/" replace />} />
          <Route path="/blockchain/gnosis" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
};
export default AppRoutes;