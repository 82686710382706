import React from "react";

const JupiterApiDocsSection: React.FC = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">
            Jupiter API Documentation
          </h2>
          <p className="text-lg text-gray-600 mt-4">
            Access the full API documentation for integrating with Jupiter's V6
            Swap API. Below are links to detailed documentation for each
            endpoint.
          </p>
        </div>
        {/* Highlighted Feature */}
        <div className="bg-blue-100 border-l-4 border-blue-500 p-6 rounded-md shadow-md mb-12">
          <h3 className="text-2xl font-bold text-blue-700 mb-3">
            <a
              href="https://docs.backpac.xyz/jupiter/overview.html"
              className="hover:text-blue-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              RPC Implementation
            </a>
          </h3>
          <p className="text-lg text-blue-600">
            The RPC implementation of Jupiter's V6 Swap API enables seamless
            integration for developers using RPC methods to harness the power of
            Jupiter's V6 Swap API on Solana.
          </p>
          <p className="text-lg text-right">
            <a
              href="https://docs.backpac.xyz/jupiter/overview.html"
              className="text-green-600 hover:text-green-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </a>
          </p>
        </div>
        <div className="space-y-6 text-left">
          <ul className="space-y-4 text-lg text-gray-600">
            <li>
              <a
                href="https://station.jup.ag/api-v6/get-quote"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                GET /quote - Retrieve a price quote for a swap
              </a>
            </li>
            <li>
              <a
                href="https://station.jup.ag/api-v6/post-swap"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                POST /swap - Execute a token swap transaction
              </a>
            </li>
            <li>
              <a
                href="https://station.jup.ag/api-v6/post-swap-instructions"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                POST /swap-instructions - Get instructions for executing a swap
              </a>
            </li>
            <li>
              <a
                href="https://station.jup.ag/api-v6/get-program-id-to-label"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                GET /program-id-to-label - Map program IDs to human-readable
                labels
              </a>
            </li>
            <li>
              <a
                href="https://station.jup.ag/api-v6/get-tokens"
                className="text-blue-600 hover:text-blue-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                GET /tokens - List all supported tokens on the Jupiter platform
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default JupiterApiDocsSection;
