
 const Plus = () => {
    const features = [
      {
        icon: ("🚦"),
        title: "Fault-Tolerant",
        desc: "Ensure maximum reliability and speed with advanced retries, and failovers. Backpac intelligently selects the fastest, most reliable RPC endpoint every time.",
      },
      {
        icon: ("🌍"),
        title: "Worldwide Presence",
        desc: "Backpac's globally distributed RPC network delivers peak performance, ensuring seamless experiences for users anywhere in the world.",
      },
      {
        icon: ("💾"),
        title: "Blockchain-Aware Caching",
        desc: "Optimize performance & reduce costs with a caching layer designed around blockchain block times. Responses are cached only as long as they're valid, avoiding credit usage.",
      },
      {
        icon: ("🧭"),
        title: "Request Auto-Routing",
        desc: "Simplify your RPC requests—Backpac automatically routes to the right RPC endpoint that supports the RPC method with automated block height routing for writes.",
      },
      {
        icon: ("⚙️"),
        title: "Zero Maintenance",
        desc: "No additional hardware or maintenance required. Configure Backpac and start enjoying fault-tolerant load balancing immediately—just set it and forget it!",
      },
      {
        icon: ("🌐"),
        title: "Unified Ecosystem Support",
        desc: "Integrate Solana and Ethereum with a single fault-tolerant RPC endpoint. Backpac supports tools like Jupiter and Jito—no switching required.",
      },
    ];

    return (
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            {/* <div className="relative z-10">
              <h3 className="font-semibold text-neutral-900 dark:text-neutral-100 text-3xl xl:text-4xl">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac's</span> Web3 RPC Load Balancer
              </h3>
              <p className="mt-3 text-neutral-700 dark:text-neutral-300">
                We believe in simplifying complexity and streamlining user
                experience. With multiple RPC node providers at your fingertips,
                we consolidate your RPC endpoints into a single RPC endpoint.
              </p>
            </div> */}
            <div
              className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
              style={{
                background:
                  "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
              }}
            ></div>
          </div>
          <div className="relative mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li
                  key={idx}
                  className="bg-white space-y-3 p-4 border rounded-lg"
                >
                  <div className="text-indigo-600 pb-2" style={{ fontSize: '1.5rem' }}>{item.icon}</div>
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {item.title}
                  </h4>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
}
export default Plus;