import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid"
import StripeUtils from "model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonSecondary from "shared/Button/ButtonSecondary";
export default function OreMiners() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const domain = window.location.origin;
  const [product, setProduct] = useState<StripeProduct>({
    id: "",
    object: "product",
    active: false,
    attributes: [],
    created: 0,
    description: "",
    images: [],
    livemode: false,
    metadata: {},
    name: "",
    package_dimensions: null,
    shippable: null,
    statement_descriptor: "null",
    type: "good",
    unit_label: "null",
    updated: 0,
    url: null,
    default_price: "",
    features: [],
    price: {
      id: "",
      object: "price",
      active: false,
      billing_scheme: "per_unit",
      created: 0,
      currency: "",
      livemode: false,
      lookup_key: "",
      metadata: {},
      nickname: "",
      product: "",
      recurring: {
        aggregate_usage: null,
        interval: "month",
        interval_count: 1,
        trial_period_days: null,
        usage_type: "licensed"
      },
      tax_behavior: "unspecified",
      tiers_mode: null,
      transform_quantity: null,
      type: "recurring",
      unit_amount: 0,
      unit_amount_decimal: "0",
      custom_unit_amount: 0
    },
    tax_code: ""
  });
  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, undefined);

      const products: Array<StripeProduct> = await _StripeUtils.retrieveOreMiner();

      //Set the data for display
      setProduct(products[0]);

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain]);


  return (
    <div
        key={1}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          typeof product.metadata !== "undefined" && product.metadata['recommended'] === "true"
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {typeof product.metadata !== "undefined" && product.metadata['recommended'] === "true" && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            MOST POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {product.unit_label.endsWith("-ent") ? "Enterprise" : product.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center dark:text-neutral-300">
            <span>{product.unit_label.endsWith("-ent") ? "Custom" : (product.price.unit_amount / 100).toFixed(2)}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
              {product.unit_label.endsWith("-ent") ? "" :product.price.recurring.interval}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {product.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item.name}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {typeof product.metadata !== "undefined" && product.metadata['recommended'] === "true" ? (
            <ButtonPrimary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', product.metadata['plan']);

              if (product.unit_label.endsWith("-ent")) {
                //e.preventDefault();
                window.open(
                  "https://meetings.hubspot.com/allyn-alford",
                  "_blank"
                );
              } else {
                //Log them into the app
                !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              }
              
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>{product.unit_label.endsWith("-ent") ? "Get Quote for Enterprise" : `Get started with ${product.metadata['plan']}`}</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', product.metadata['plan']);
              if (product.unit_label.endsWith("-ent")) {
                //e.preventDefault();
                window.open(
                  "https://meetings.hubspot.com/allyn-alford",
                  "_blank"
                );
              } else {
                //Log them into the app
                !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              }
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>
              <span className="font-medium">{product.unit_label.endsWith("-ent") ? "Get Quote for Enterprise" : `Get started with ${product.metadata['plan']}`}</span>
            </ButtonSecondary>
          )}
          
          <p className="text-xs text-neutral-500 dark:text-neutral-300 mt-3">
            {product.statement_descriptor}
          </p>
        </div>
      </div>
  );
}
