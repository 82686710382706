import { CheckIcon } from "@heroicons/react/24/solid";
import { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
//import { PopupButton } from "@typeform/embed-react";
import { useAuth0 } from '@auth0/auth0-react';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import StripeUtils from "../../model/StripeUtils";
import { StripeProduct } from "interfaces/StripeProduct";
import Enterprise from "containers/PageHome/sections/Enterprise";
import FeaturesCompare from "./FeaturesCompare";
import BookADemo from "./BookADemo";
//import PricingHeading from "components/Heading/PricingHeading";
export interface PageSubscriptionProps {
  className?: string;
  cache?: any
}

export interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  desc: string;
  per: string;
  features: string[];
};

const PageSubscription: FC<PageSubscriptionProps> = ({ className = "", cache }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const domain = window.location.origin;
  //const { isLoading } = useAuth0();
  //const [init, setInit] = useState<boolean>(true);
  //const [loading, setLoading] = useState<boolean>(isLoading);
  const [products, setProducts] = useState<Array<StripeProduct>>([]);

  useEffect(() => {
    const getProduct = async () => {
    //setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain, cache);

      const products: Array<StripeProduct> = await _StripeUtils.retrieveProducts();

      //Set the data for display
      setProducts(products);

      //Stop loading
      //setLoading(false);

      //Show the UI
      //setInit(false);
    };
    //Grab the products and pricing data for the UI
    getProduct();
  }, [domain, cache]);


  const renderPricingItem = (pricing: StripeProduct, index: number) => {
    return (
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 flex flex-col overflow-hidden ${
          typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true"
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >
        {typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" && (
          <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            MOST POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
            {pricing.unit_label.endsWith("-ent") ? "Enterprise" : pricing.name}
          </h3>
          <h2 className="text-5xl leading-none flex items-center dark:text-neutral-300">
            <span>{pricing.unit_label.endsWith("-ent") ? "Custom" : (pricing.price.unit_amount / 100).toFixed(2)}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500 dark:text-neutral-300">
              {pricing.unit_label.endsWith("-ent") ? "" :pricing.price.recurring.interval}
            </span>
          </h2>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                <CheckIcon className="w-5 h-5" aria-hidden="true" />
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item.name}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto">
          {typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" ? (
            <ButtonPrimary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', pricing.metadata['plan']);

              if (pricing.unit_label.endsWith("-ent")) {
                //e.preventDefault();
                window.open(
                  "https://meetings.hubspot.com/allyn-alford",
                  "_blank"
                );
              } else {
                //Log them into the app
                !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              }
              
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>{pricing.unit_label.endsWith("-ent") ? "Get Quote for Enterprise" : `Get started with ${pricing.metadata['plan']}`}</ButtonPrimary>
          ) : (
            <ButtonSecondary onClick={()=>{
              //Add the plan to the session
              sessionStorage.setItem('plan', pricing.metadata['plan']);
              if (pricing.unit_label.endsWith("-ent")) {
                //e.preventDefault();
                window.open(
                  "https://meetings.hubspot.com/allyn-alford",
                  "_blank"
                );
              } else {
                //Log them into the app
                !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
              }
              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
            }}>
              <span className="font-medium">{pricing.unit_label.endsWith("-ent") ? "Get Quote for Enterprise" : `Get started with ${pricing.metadata['plan']}`}</span>
            </ButtonSecondary>
          )}
          
            {/* <PopupButton
                id={"GUMgTayZ"}
                className={"nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors px-4 py-3 sm:px-6 text-sm sm:text-base font-medium" + (typeof pricing.metadata !== "undefined" && pricing.metadata['recommended'] === "true" ? "ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50" : "ttnc-ButtonSecondary border bg-secondary-100 border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-secondary-400 dark:hover:bg-secondary-800")}
                size={66}
                medium="demo-test"
                hidden={{ plan: pricing.metadata['plan'], hubspot_page_url: document.location.href, hubspot_page_name: pricing.metadata['plan'], }}
              >
                Get started with {pricing.metadata['plan']}
              </PopupButton> */}
          <p className="text-xs text-neutral-500 dark:text-neutral-300 mt-3">
            {pricing.statement_descriptor}
          </p>
        </div>
      </div>
    );
  };

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/pricing",
    "name": "Pricing | Backpac",
    "description": "Discover the pricing plans for Backpac's blockchain data solutions. Find the perfect plan for your business and unlock scalable blockchain access for Ethereum, Solana, and more.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Pricing",
          "item": "https://www.backpac.xyz/pricing"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Pricing | Backpac",
      "about": "Find the ideal pricing plan for Backpac's blockchain data solutions, including Ethereum, Solana, and other blockchain platforms.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/assets/images/pricing-plans.png",
        "caption": "Backpac Pricing Plans"
      },
      "offers": {
        "@type": "OfferCatalog",
        "name": "Backpac Pricing Plans",
        "url": "https://www.backpac.xyz/pricing",
        "itemListElement": [
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/pricing#velocity",
            "name": "Velocity Plan",
            "priceCurrency": "USD",
            "price": "479.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "479.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Blockchain Data Access",
            "features": [
              "*175M Requests (then $4/M)",
              "Up to 250 Request /sec",
              "Custom Load Balancer Listener Rules",
              "Intelligent RPC Method Routing",
              "Block Height Routing",
              "Cost-Reducing Smart Caching",
              "Custom Load Balancer Target Groups",
              "Backpac RPC Endpoints*",
              "Custom RPC Endpoints",
              "Primary Support",
              "Global Regions"
            ]
          },
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/pricing#throughput",
            "name": "Throughput Plan",
            "priceCurrency": "USD",
            "price": "949.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "949.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Blockchain Data Access",
            "features": [
              "*150M Requests (then $4/M)",
              "Up to 500 Request /sec",
              "Custom Load Balancer Listener Rules",
              "Intelligent RPC Method Routing",
              "Block Height Routing",
              "Cost-Reducing Smart Caching",
              "Custom Load Balancer Target Groups",
              "Backpac RPC Endpoints*",
              "Custom RPC Endpoints",
              "Premium Support",
              "Global Regions"
            ]
          },
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/pricing#highthroughput",
            "name": "High Throughput Plan",
            "priceCurrency": "USD",
            "price": "1849.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "1849.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Blockchain Data Access",
            "features": [
              "*300M Requests (then $3/M)",
              "Up to 1,000 Request /sec",
              "Custom Load Balancer Listener Rules",
              "Intelligent RPC Method Routing",
              "Block Height Routing",
              "Cost-Reducing Smart Caching",
              "Custom Load Balancer Target Groups",
              "Backpac RPC Endpoints*",
              "Custom RPC Endpoints",
              "Premium Support",
              "Global Regions"
            ]
          }
        ]
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Backpac Pricing - Flexible Plans for Blockchain Solutions</title>
        <meta name="description" content="Explore Backpac's flexible pricing plans for blockchain data solutions. Choose a plan tailored to your needs and scale your blockchain projects efficiently." />

        <meta name="keywords" content="Backpac pricing, Blockchain pricing plans, Flexible blockchain solutions, Blockchain data access pricing, Scalable blockchain tools, Blockchain services cost" />
        <meta property="og:title" content="Backpac Pricing - Flexible Plans for Blockchain Solutions" />
        <meta property="og:description" content="Choose the right plan for your blockchain needs. Backpac offers flexible pricing options to scale your blockchain projects effortlessly." />
        <meta property="og:url" content="https://www.backpac.xyz/pricing" />
        <meta name="twitter:title" content="Backpac Pricing - Flexible Plans for Blockchain Solutions" />
        <meta name="twitter:description" content="Explore Backpac's flexible pricing plans designed for blockchain developers and businesses. Scale your projects with confidence." />
        <meta
          name="twitter:image:alt"
          content="Backpac Pricing - Flexible Plans for Blockchain Solutions"
        />
        <meta
          property="og:image:alt"
          content="Backpac Pricing - Flexible Plans for Blockchain Solutions"
        />
        <link rel="canonical" href="https://www.backpac.xyz/pricing"></link>
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
    <div
      className={`nc-PageSubscription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubscription"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* <span className="mr-4 text-3xl md:text-4xl leading-none">💎</span> */}
          Simplified blockchain access pricing
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Backpac provides businesses with reliable <b>Ethereum</b> and <b>Solana</b> blockchain access and a
          simple pricing model, allowing you to get started in less than a
          minute.
        </span>
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden">
        <div className="grid lg:grid-cols-3 gap-5 xl:gap-8">
          {products.map(renderPricingItem)}
        </div>


      {/* Enterprise */}
      <Enterprise />
      {/* Feature Comparison Chart, hidden on mobile view */}
      <div style={{ display: window.innerWidth <= 500 ? 'none' : 'block' }}>
      <FeaturesCompare />
      </div>
      {/* Book a Demo with Backpac */}
      <BookADemo />

      </section>
    </div>
    </>
  );
};

export default PageSubscription;
