import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PricingCard from "./PricingCard";
import PopupDialog from "./PopupDialog";
import ApiOverviewSection from "./ApiOverviewSection";
import JupiterApiDocsSection from "./JupiterApiDocsSection";

export interface PageJupiterPricingProps {
  className?: string;
  cache?: any
}

const PageJupiterPricing: FC<PageJupiterPricingProps> = ({ className = "", cache }) => {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const product = {
    title: "Metis Jupiter v6 Swap API",
    description:
      "Access the most advanced swap API on the Metis network. Execute fast and reliable token swaps with low fees.",
    features: [
      "Low-latency token swaps",
      "Highly reliable API uptime",
      "Supports multiple token pairs",
      "Scalable for enterprise use",
    ],
    pricingTiers: [
      {
        title: "Essential",
        price: "$249/month",
        description: "Best for individual developers and small-scale projects.",
        features: [
          "Up to 50 requests per second.",
          "Up to 130,000,000 requests per month.",
          "Available through both RPC and REST API",
          "Set platform fees on quotes.",
          "Fresh data w/rolling restarts.",
          "Get Routes for a swap.",
          "Serialized transactions for the swap.",
          "Individual instructions for the swap.",
        ],
      },
      {
        title: "Professional",
        price: "$500/month",
        description: "Perfect for small teams or medium-scale projects.",
        features: [
          "Up to 125 requests per second.",
          "Up to 325,000,000 requests per month.",
          "Available through both RPC and REST API.",
          "Set platform fees on quotes.",
          "Fresh data w/rolling restarts.",
          "Get Routes for a swap.",
          "Serialized transactions for the swap.",
          "Individual instructions for the swap.",
        ],
      },
      {
        title: "Elite",
        price: "1,199/month",
        description: "Ideal for large teams and enterprise needs.",
        features: [
          "Up to 300 requests per second.",
          "Up to 776,000,000 requests per month.",
          "Available through both RPC and REST API.",
          "Set platform fees on quotes.",
          "Fresh data w/rolling restarts.",
          "Get Routes for a swap.",
          "Serialized transactions for the swap.",
          "Individual instructions for the swap.",
        ],
      },
    ],
  };

  const handleGetStarted = (planName: string) => {
    setSelectedPlan(planName);
  };

  const closePopup = () => {
    setSelectedPlan(null);
  };



  useEffect(() => {
  
  }, [cache]);

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/pricing",
    "name": "Hosted Metis Jupiter v6 Swap API",
    "description": "Access the Metis Jupiter V6 Swap API for lightning-fast token swaps with clear and reliable rate limits. Choose from flexible pricing plans tailored for developers, businesses, and enterprises.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Metis Jupiter V6 Swap API",
          "item": "https://www.backpac.xyz/add-on/jupiter"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Pricing | Backpac",
      "about": "Find the ideal pricing plan for Hosted Metis Jupiter V6 Swap API, including Ethereum, Solana, and other blockchain platforms.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/assets/images/pricing-plans.png",
        "caption": "Metis Jupiter V6 Swap API"
      },
      "offers": {
        "@type": "OfferCatalog",
        "name": "Metis Jupiter V6 Swap API",
        "url": "https://www.backpac.xyz/add-on/jupiter",
        "itemListElement": [
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/add-on/jupiter#essential",
            "name": "Essential",
            "priceCurrency": "USD",
            "price": "249.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "249.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Metis Jupiter V6 Swap API",
            "features": [
              "Up to 50 requests per second.",
              "Up to 130,000,000 requests per month.",
              "Set platform fees on quotes",
              "Get Routes for a swap",
              "Serialized transactions for the swap",
              "Individual instructions for the swap",
            ]
          },
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/add-on/jupiter#professional",
            "name": "Professional",
            "priceCurrency": "USD",
            "price": "500.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "500.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Metis Jupiter V6 Swap AP",
            "features": [
              "Up to 125 requests per second.",
              "Up to 325,000,000 requests per month.",
              "Set platform fees on quotes",
              "Get Routes for a swap",
              "Serialized transactions for the swap",
              "Individual instructions for the swap",
            ]
          },
          {
            "@type": "Offer",
            "url": "https://www.backpac.xyz/add-on/jupiter#elite",
            "name": "Elite",
            "priceCurrency": "USD",
            "price": "1199.00",
            "eligibleRegion": {
              "@type": "Place",
              "name": "Worldwide"
            },
            "priceSpecification": {
              "@type": "PriceSpecification",
              "price": "1199.00",
              "priceCurrency": "USD",
              "priceType": "Retail",
              "valueAddedTaxIncluded": false
            },
            "additionalType": "https://schema.org/Service",
            "serviceType": "Metis Jupiter V6 Swap API",
            "features": [
              "Up to 300 requests per second.",
              "Up to 776,000,000 requests per month.",
              "Set platform fees on quotes",
              "Get Routes for a swap",
              "Serialized transactions for the swap",
              "Individual instructions for the swap",
            ]
          }
        ]
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Hosted Metis Jupiter V6 Swap API</title>
        <meta name="description" content="Access the Metis Jupiter V6 Swap API for lightning-fast token swaps with clear and reliable rate limits. Choose from flexible pricing plans tailored for developers, businesses, and enterprises." />
        <meta name="keywords" content="Metis Jupiter API, Swap API, Token Swaps, Jupiter SDK, Reliable Rate Limits, Fast API Calls, Pricing Plans, Developer Tools, Enterprise API, Metis Network" />
        <meta property="og:title" content="Metis Jupiter V6 Swap API - Fast & Reliable Token Swaps" />
        <meta property="og:description" content="Make lightning-fast API calls via the Jupiter SDK with reliable rate limits. Flexible pricing plans available for every need." />
        <meta property="og:url" content="https://www.backpac.xyz/add-on/jupiter" />
        <meta name="twitter:title" content="Metis Jupiter V6 Swap API" />
        <meta name="twitter:description" content="Make lightning-fast API calls via the Jupiter SDK with reliable rate limits. Flexible pricing plans available for every need." />
        <meta
          name="twitter:image:alt"
          content="Metis Jupiter V6 Swap API"
        />
        <meta
          property="og:image:alt"
          content="Metis Jupiter V6 Swap API"
        />
        <link rel="canonical" href="https://www.backpac.xyz/add-on/jupiter"></link>
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
    <div
      className={`nc-PageSubscription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageSubscription"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Metis Jupiter V6 Swap API
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
        Leverage the power of Metis Jupiter V6 Swap API to access liquidity on Solana with lightning-fast performance and reliable rate limits.
        </span>
      </header>
      <ApiOverviewSection />
      <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* <h1 className="text-4xl font-bold text-gray-800 text-center mb-4">
          {product.title}
        </h1>
        <p className="text-lg text-gray-600 text-center mb-8">{product.description}</p> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {product.pricingTiers.map((tier, index) => (
            <PricingCard key={index} tier={tier} onGetStarted={() => handleGetStarted(tier.title)}/>
          ))}
        </div>
      </div>
    </div>
    <JupiterApiDocsSection />
    </div>
    {selectedPlan && (
        <PopupDialog
          planName={selectedPlan}
          onClose={closePopup}
        />
      )}
    </>
  );
};
export default PageJupiterPricing;