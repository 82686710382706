//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Hero from "./sections/Hero";
import Benefits from "./sections/Benefits";
import RPCProviders from "shared/RPCProviders/RPCProviders";
import SolanaRPC from "./sections/SolanaRPC";
const ORE = () => {
  useEffect(() => {


  }, []);

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/blockchain/solana/ore-mining",
    "name": "Ore Mining on Solana Blockchain | Backpac",
    "description": "Ore Mining on the Solana Blockchain with Backpac. Explore how to leverage Backpac to land transactions while mining ORE on Solana Blockchain.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Solana",
          "item": "https://www.backpac.xyz/blockchain/solana"
        },
        {
          "@type": "ListItem",
          "position": 3,
          "name": "Solana Ore Mining",
          "item": "https://www.backpac.xyz/blockchain/solana/ore-mining"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Ore Mining on Solana Blockchain | Backpac",
      "about": "Explore how to leverage Backpac to land transactions while mining ORE on Solana Blockchain.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/assets/images/solana-ore-mining.jpg",
        "caption": "Land transaction while Mining Ore on Solana Blockchain"
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta name="title" content="Ore Mining on Solana Blockchain | Backpac" />
        <meta name="description" content="Explore Ore Mining on the Solana Blockchain with Backpac. Explore how to leverage Backpac to land transactions while mining ORE on Solana Blockchain." />
        <meta name="keywords" content="land transactions, Ore Mining, Solana Blockchain, Mining on Solana, Blockchain Mining Solutions, Solana Mining, Backpac" />
        <meta property="og:title" content="Ore Mining on Solana Blockchain | Backpac" />
        <meta property="og:description" content="Ore Mining on the Solana Blockchain with Backpac. Explore how to leverage Backpac to land transactions while mining ORE on Solana Blockchain." />
        <meta property="og:url" content="https://www.backpac.xyz/blockchain/solana/ore-mining" />
        <meta name="twitter:title" content="Ore Mining on Solana Blockchain | Backpac" />
        <meta name="twitter:description" content="Ore Mining on the Solana Blockchain with Backpac. Explore how to leverage Backpac to land transactions while mining ORE on Solana Blockchain." />
        <link rel="canonical" href="https://www.backpac.xyz/blockchain/solana/ore-mining" />
        <meta
          name="twitter:image:alt"
          content="Mine Solana ORE"
        />
        <meta
          property="og:image:alt"
          content="Solana Ore Mining"
        />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <Hero />
      <Benefits/>
      <RPCProviders blockchain={"solana"} />
      <SolanaRPC />
     
    </>
  );
};
export default ORE;
