
 const Plus = () => {
    const features = [
      {
        icon: ("🌐"),
        title: "Load Balanced Jito Endpoint",
        desc: "The Backpac load balancer provides a single unified RPC endpoint for interacting with Solana and Jito APIs. This endpoint simplifies development and ensures efficient request distribution across multiple backend nodes for maximum reliability and performance.",
      },
      {
        icon: ("📦"),
        title: "sendBundle",
        desc: "The sendBundle method allows developers to submit bundles of pre-signed transactions. This feature is essential for low-latency use cases where precise transaction execution is critical. Backpac ensures these bundles are processed efficiently through its load-balancing system.",
      },
      {
        icon: ("📊"),
        title: "getBundleStatuses",
        desc: "The getBundleStatuses method retrieves the status of previously submitted bundles. It provides real-time updates on the progress of transactions, enabling developers to monitor their operations closely and ensure successful execution.",
      },
      {
        icon: ("💰"),
        title: "getTipAccounts",
        desc: "The getTipAccounts method enables users to retrieve tip account information for Solana validators. This feature supports applications that require detailed validator tip data for optimal transaction prioritization.",
      },
      {
        icon: ("✈️"),
        title: "getInflightBundleStatuses",
        desc: "The getInflightBundleStatuses method tracks bundles currently in the pipeline. It helps developers understand the state of transactions in progress and troubleshoot potential delays or issues efficiently.",
      },
      {
        icon: ("✉️"),
        title: "sendTransaction",
        desc: "The sendTransaction method serves as a direct proxy to the Solana sendTransaction RPC method. This ensures your transaction is forwarded directly to the validator for optimal performance. By default, transactions submitted through this method include MEV (Miner Extractable Value) protection.",
      },
    ];

    return (
      <section className="py-14">
        <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            {/* <div className="relative z-10">
              <h3 className="font-semibold text-neutral-900 dark:text-neutral-100 text-3xl xl:text-4xl">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac's</span> Web3 RPC Load Balancer
              </h3>
              <p className="mt-3 text-neutral-700 dark:text-neutral-300">
                We believe in simplifying complexity and streamlining user
                experience. With multiple RPC node providers at your fingertips,
                we consolidate your RPC endpoints into a single RPC endpoint.
              </p>
            </div> */}
            <div
              className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
              style={{
                background:
                  "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
              }}
            ></div>
          </div>
          <div className="relative mt-12">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((item, idx) => (
                <li
                  key={idx}
                  className="bg-white space-y-3 p-4 border rounded-lg"
                >
                  <div className="text-indigo-600 pb-2" style={{ fontSize: '1.5rem' }}>{item.icon}</div>
                  <h4 className="text-lg text-gray-800 font-semibold">
                    {item.title}
                  </h4>
                  <p>{item.desc}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
}
export default Plus;