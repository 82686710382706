import axios from "axios";
import { StripeProduct } from "interfaces/StripeProduct";
import { StripePrice } from "interfaces/StripePrice";


export default class StripeUtils {
  domain: string;
  stripeURL: string;
  Authorization: string;
  ContentType: string;
  Prices: Array<StripePrice>;
  cache: any | undefined;

  constructor(domain: string, cache: any | undefined) {
    this.domain = domain;
    this.stripeURL = "https://api.stripe.com/v1";
    this.Authorization = `Bearer ${process.env.REACT_APP_STRIPE_SK}`;
    this.ContentType = "application/x-www-form-urlencoded";
    this.Prices = [] || undefined;
    this.cache = cache;
  }
  createCheckoutSession = async (id: string) => {
    try {
      const data = {
        mode: "subscription",
        success_url: `${this.domain}/newsletter?success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${this.domain}/newsletter?canceled=true`,
        allow_promotion_codes: true,
        line_items: [
          {
            // price: prices.data[0].id,
            price: id,
            // For metered billing, do not pass quantity
            quantity: 1,
          },
        ],
      };
      const qs = require("qs");
      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/checkout/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: qs.stringify(data),
      });
      return response.data.url;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveProduct = async (productId: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products/${productId}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data;
    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  };
  sortProductsByIndex = (arr: Array<StripeProduct>): Array<StripeProduct> => {
    return arr.sort((a, b) => Number(a.metadata.index) - Number(b.metadata.index));
  };
  retrieveProducts = async () => {
    try {

      //Hold a list of products to show to the UI
      let products: Array<StripeProduct> = [];
      const cachedProducts: Array<StripeProduct> = this.cache.get(`ui-products`);

      if (typeof cachedProducts === "undefined") {
        //Make the call for the products
        const response = await axios({
          method: "get",
          url: `${this.stripeURL}/products`,
          headers: {
            Authorization: this.Authorization,
            "Content-Type": this.ContentType,
          },
        });

        //Grab the list of products
        const _products = response.data.data;

        //Loop the products and get the prices
        for (const product of _products) {
          //Make sure this product should be shown in the UI
          if (product.unit_label !== null && product.unit_label.endsWith("-ui")) {
            const price = await this.retrievePrice(product.default_price);
            //Make sure we have a price for the product, otherwise don't add it for display
            if (typeof price !== "undefined") {
              product.price = price;
              products.push(product as StripeProduct);
            }
          }
        }
      } else {
        products = cachedProducts;
      }

 

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveOreMiner = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label === "oreminer"){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveVelocity = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label === "velocity-ui"){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveEnterprise = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label.endsWith('-ent')){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };

  retrieveArweaveEnterprise = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];

      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label === 'arweaveent'){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveArweave = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label === "arweave"){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrievePrice = async (priceId: string) => {
    try {

      //Check if we already have the products price in the list
      let price = this.Prices.find(item => item.id === priceId)
      //If not retrive it
      if(typeof price === "undefined"){
        const response = await axios({
          method: "get",
          url: `${this.stripeURL}/prices/${priceId}`,
          headers: {
            Authorization: this.Authorization,
            "Content-Type": this.ContentType,
          },
        });
        //Grab the price
        price = response.data;
        //If it's not missing, add the product
        if(typeof price !== "undefined") this.Prices.push(price)
      }

      return price;
    } catch (error: any) {
      console.error(error)
      console.log(error?.data.error.message);
      throw error;
    }
  };
  createBillingPortalSession = async (email: string) => {
    try {
      const customer = await this.searchCustomerByEmail(email);

      //Make sure the customer exists within stripe
      if (customer.length === 0) {
        //If they don't exists stop here
        return { success: false };
      }

      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/billing_portal/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: {
          customer: customer[0].id,
          return_url: `${this.domain}/newsletter`,
        },
      });
      return {url: response.data.url, customer: response.data.customer};
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  searchCustomerByEmail = async (email: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/customers?email=${email}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
}