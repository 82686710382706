import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Alchemy from 'images/providers/alchemy.png';
import Infura from 'images/providers/infura.png';
import Ankr from 'images/providers/ankr.png';
import ChainStack from 'images/providers/chainstack.png';
const Providers = () => {
    return (
      <div className="bg-white dark:bg-neutral-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="relative max-w-2xl mx-auto sm:text-center">
            <div className="relative z-10">
              <h3 className="font-semibold text-neutral-900 dark:text-white text-3xl xl:text-4xl">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac</span> RPC Network
              </h3>
              <p className="mt-3 text-neutral-900 dark:text-white">
                <span style={{ color: "#1D476D" }}>Back</span>
                <span style={{ color: "#3BA5F3" }}>pac</span> &trade;
                exclusively routes RPC requests through reliable blockchain node
                providers, enabling organizations and users to access features
                seamlessly via a single RPC endpoint.
              </p>
            </div>
            <div
              className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]"
              style={{
                background:
                  "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)",
              }}
            ></div>
          </div>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={Alchemy}
              alt="Alchemy Blockchain RPC Node Provider"
              width={158}
              height={48}
              data-tooltip-id="my-tooltip-Alchemy"
              data-tooltip-content="Alchemy RPC Node Provider"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src={Infura}
              alt="Infura Blockchain RPC Node Provider"
              width={158}
              height={48}
              data-tooltip-id="my-tooltip-Infura"
              data-tooltip-content="Infura RPC Node Provider"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src={Ankr}
              alt="Ankr Blockchain RPC Node Provider"
              width={158}
              height={48}
              data-tooltip-id="my-tooltip-Ankr"
              data-tooltip-content="Ankr RPC Node Provider"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src={ChainStack}
              alt="ChainStack Blockchain RPC Node Provider"
              width={158}
              height={48}
              data-tooltip-id="my-tooltip-ChainStack"
              data-tooltip-content="ChainStack RPC Node Provider"
            />
            <Tooltip id="my-tooltip" />
          </div>
        </div>
      </div>
    );
  }
export default Providers;  