//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import EthereumResources from "./sections/EthereumResources";
//import ProviderLogoCloud from "./sections/ProviderLogoCloud";
import Providers from "containers/PageHome/sections/Providers";
import Hero from "./sections/Hero";
import Enterprise from "containers/PageHome/sections/Enterprise";
import Benefits from "./sections/Benefits";
const Ethereum = () => {
  useEffect(() => {


  }, []);

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/blockchain/ethereum",
    "name": "Ethereum Blockchain Data Solutions | Backpac",
    "description": "Access scalable Ethereum blockchain data solutions with Backpac. Simplify operations, reduce costs, and streamline Ethereum blockchain access for your business.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Ethereum",
          "item": "https://www.backpac.xyz/blockchain/ethereum"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Ethereum Blockchain Data Solutions | Backpac",
      "about": "Scalable blockchain data access solutions for the Ethereum blockchain.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/static/media/Providers2.c8e6d500d388ffc18ff2.png",
        "caption": "Ethereum Blockchain Solutions by Backpac"
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Backpac for Ethereum - Scalable Blockchain Data Solutions
        </title>
        <meta name="description" content="Backpac delivers efficient, scalable data solutions for Ethereum blockchain projects. Simplify development and optimize your blockchain data access. Start now!" />

        <meta name="keywords" content="Ethereum blockchain, Ethereum data access, Ethereum API, Scalable blockchain solutions, Ethereum developers, Blockchain infrastructure, Backpac for Ethereum, Mainnet, Goerli, Sepolia, Holesky" />
        <meta
          name="twitter:image:alt"
          content="Backpac for Ethereum - Scalable Blockchain Data Solutions"
        />
        <meta
          property="og:image:alt"
          content="Backpac for Ethereum - Scalable Blockchain Data Solutions"
        />
        <meta name="twitter:title" content="Backpac for Ethereum - Scalable Blockchain Data Solutions" />
        <meta name="twitter:description" content="Simplify your Ethereum blockchain projects with Backpac's scalable data access solutions. Perfect for developers and businesses. Learn more today!" />
        <meta property="og:title" content="Backpac for Ethereum - Scalable Blockchain Data Solutions" />
        <meta property="og:description" content="Backpac provides seamless and scalable data solutions for Ethereum blockchain projects. Streamline your development process today." />
        <meta property="og:url" content="https://www.backpac.xyz/blockchain/ethereum" />
        <link rel="canonical" href="https://www.backpac.xyz/blockchain/ethereum" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <Hero />
      <Benefits/>
      <EthereumResources />
      <Providers />
      <Enterprise />
    </>
  );
};
export default Ethereum;
