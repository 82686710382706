import React, { useState } from "react";
import Slack from 'model/Slack';
import { CheckIcon, ExclamationCircleIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { toast, ToastContainer } from 'react-toastify';
interface PopupDialogProps {
  planName: string;
  onClose: () => void;
}

const PopupDialog: React.FC<PopupDialogProps> = ({ planName, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
  });
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: `Hello, 

I am interested in learning more about the ${planName} plan and how it can meet my project's needs. Please provide additional details regarding setup, API documentation, and any onboarding support available.

Thank you!`
    });
  
  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validateForm = () => {
    let valid = true;
    let nameError = "";
    let emailError = "";

    // Name validation
    if (!formData.name) {
      nameError = "Name is required.";
      valid = false;
    }

    // Email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email) {
      emailError = "Email is required.";
      valid = false;
    } else if (!emailPattern.test(formData.email)) {
      emailError = "Please enter a valid email address.";
      valid = false;
    }

    setErrors({ name: nameError, email: emailError });

    return valid;
  };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const subject = `Interest in ${planName} Plan`;
        //console.log({subject, email, name, message});
        const name = formData.name;
        const email = formData.email;
        const message = formData.message;
        const slack = new Slack(undefined);
        if (validateForm()) {
            toast.promise(
                slack.contactUs(email, name, subject, message),
                {
                    pending: {
                        render() {
                            setLoading(true);
                            return "Sending message"
                        },
                        icon: <PaperAirplaneIcon className="w-5 h-5" />,
                    },
                    success: {
                        render({ data }) {
                            setFormData({
                                name: "",
                                email: "",
                                message: "",
                            });
                            onClose(); // Close the dialog after submission
                            return `Message received. Thank you ${name}`
                        },
                        // other options
                        icon: <CheckIcon className="w-5 h-5" />,
                    },
                    error: {
                        render({ data }) {
                            // When the promise reject, data will contains the error
                            return `Error: ${data}`
                        },
                        icon: <ExclamationCircleIcon className="w-5 h-5" />
                    },
                }
            )
        }

    }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 shadow-lg max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">
                  Contact Us About the Metis Jupiter V6 Swap API {planName} Plan
              </h2>
              <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-medium mb-2">
                          Name
                      </label>
                      <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                          className="w-full p-2 border rounded-md"
                      />
                      {errors.name && (
                          <p className="text-red-500 text-sm mt-1">{errors.name}</p>
                      )}
                  </div>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-medium mb-2">
                          Email
                      </label>
                      <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                          className="w-full p-2 border rounded-md"
                      />
                      {errors.email && (
                          <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                      )}
                  </div>
                  <div className="mb-4">
                      <label className="block text-gray-700 font-medium mb-2">
                          Message
                      </label>
                      <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          className="w-full p-2 border rounded-md"
                          rows={4}
                      ></textarea>
                  </div>
                  <div className="flex justify-end">
                      <button
                          type="button"
                          className="text-gray-600 mr-4"
                          onClick={onClose}
                      >
                          Cancel
                      </button>
                      <button
                          type="submit"
                          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                      >
                          Submit
                      </button>
                  </div>
              </form>
              <ToastContainer />
          </div>
     
    </div>
  );
};

export default PopupDialog;
