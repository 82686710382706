import React from "react";
import { Helmet } from "react-helmet";
// import SectionAds from "./SectionAds";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine from "./SectionMagazine";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

interface BlogPageProps {
  cache: any
}

const BlogPage: React.FC<BlogPageProps> = ({cache}) => {

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/blog",
    "name": "Backpac Blog | Insights on Blockchain and Technology Solutions",
    "description": "Read the latest articles on blockchain, technology solutions, and innovations from Backpac. Stay updated with insights, case studies, and industry trends.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Blog",
          "item": "https://www.backpac.xyz/blog"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Backpac Blog | Insights on Blockchain and Technology Solutions",
      "about": "The Backpac Blog offers insights into blockchain technology, industry trends, case studies, and innovations in technology solutions.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://www.backpac.xyz/assets/images/blog-og-image.jpg",
        "caption": "Backpac Blog Insights"
      }
    }
  }

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>Backpac Blog | Insights on Blockchain and Technology Solutions</title>
        <meta name="title" content="Backpac Blog | Insights on Blockchain and Technology Solutions" />
        <meta name="description" content="Read the latest articles on blockchain, technology solutions, and innovations from Backpac. Stay updated with insights, case studies, and industry trends." />
        <meta name="keywords" content="Blockchain blog, technology solutions, Backpac blog, blockchain industry, blockchain insights, technology trends, case studies, innovations" />
        <meta property="og:title" content="Backpac Blog | Insights on Blockchain and Technology Solutions" />
        <meta property="og:description" content="Read the latest articles on blockchain, technology solutions, and innovations from Backpac. Stay updated with insights, case studies, and industry trends." />
        <meta property="og:url" content="https://www.backpac.xyz/blog" />
        <meta name="twitter:title" content="Backpac Blog | Insights on Blockchain and Technology Solutions" />
        <meta name="twitter:description" content="Read the latest articles on blockchain, technology solutions, and innovations from Backpac. Stay updated with insights, case studies, and industry trends." />
        <link rel="canonical" href="https://backpac.xyz/blog" />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      <div className="container relative">
        {/* === SECTION 1 === */}
        <div className="pt-12 pb-16 lg:pb-28">
          <SectionMagazine cache={cache}/>
        </div>

        {/* === SECTION 1 === 
        <SectionAds />
*/}
        {/* === SECTION 8 === */}
        <SectionLatestPosts className="py-16 lg:py-28" cache={cache}/>

        {/* === SECTION 1 === 
        <SectionSubscribe2 className="pb-16 lg:pb-28" />
        */}
      </div>
    </div>
  );
};

export default BlogPage;
