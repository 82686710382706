import React from "react";

const ApiOverviewSection: React.FC = () => {
  return (
    <section className="py-16">
      <div className="container mx-auto px-6">
        {/* <div className="text-center mb-12">
          <h2 className="text-3xl font-semibold text-gray-800">
            API Overview
          </h2>
          <p className="text-lg text-gray-600 mt-4">
            Leverage the power of Metis Jupiter V6 Swap API to access liquidity
            on Solana with lightning-fast performance and reliable rate limits.
          </p>
        </div> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Fast & Reliable</h3>
            <p className="text-gray-600 mt-4">
              Make lightning-fast calls with low latency, no server maintenance,
              and access to real-time markets and pools.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Independent Node</h3>
            <p className="text-gray-600 mt-4">
              This add-on uses its own Solana validator node, ensuring maximum
              reliability.
            </p>
          </div>

          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-semibold text-gray-800">Customizable Quotes</h3>
            <p className="text-gray-600 mt-4">
              Get quotes with control over slippage, platform fees, swap transactions,
              instructions, and priority fees.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApiOverviewSection;
