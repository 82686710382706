import React from 'react';

interface Tier {
  title: string;
  price: string;
  description: string;
  features: string[];
}

const PricingCard = ({ tier,  onGetStarted}: { tier: Tier, onGetStarted: any}) => (
    <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-200">
    <h3 className="text-xl font-bold text-gray-800">{tier.title}</h3>
    <p className="text-2xl font-semibold text-blue-600 my-4">{tier.price}</p>
    <p className="text-gray-600 mb-4">{tier.description}</p>
    <ul className="list-disc list-inside text-gray-700 space-y-2 mb-4">
      {tier.features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
    <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700" onClick={onGetStarted}>
      Get Started
    </button>
  </div>
);

export default PricingCard;
