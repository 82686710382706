//import Flow from "./flow/Flow";
import { useEffect  } from "react";
import { Helmet } from "react-helmet";
import Hero from './section/Hero';
import Benefits from "./section/Benefits";
import Plus from "./section/Plus";
import HowToSection from "./section/HowTo";
const Jito = () => {
  useEffect(() => {


  }, []);

  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.backpac.xyz/blockchain/solana",
    "name": "Load Balancer for Jito APIs | Backpac",
    "description": "Leverage multiple Jito endpoints via a single RPC endpoint. Interact seamlessly with both Solana and Jito for unparalleled speed, reliability, and scalability.",
    "inLanguage": "en",
    "breadcrumb": {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://www.backpac.xyz/"
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Jito Load Balancer",
          "item": "https://backpac.xyz/jito-load-balancer"
        }
      ]
    },
    "publisher": {
      "@type": "Organization",
      "name": "Backpac",
      "url": "https://www.backpac.xyz",
      "logo": "https://image.nft.backpac.xyz/crop.png",
      "sameAs": [
        "https://www.linkedin.com/company/backpacxyz/",
        "https://twitter.com/BackpacXYZ"
      ]
    },
    "mainEntity": {
      "@type": "WebPage",
      "headline": "Load Balancer for Jito APIs | Backpac",
      "about": "Load Balancer for Jito APIs endpoints.",
      "creator": {
        "@type": "Organization",
        "name": "Backpac",
        "url": "https://www.backpac.xyz"
      },
      "primaryImageOfPage": {
        "@type": "ImageObject",
        "url": "https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg",
        "caption": "Load Balancer for Jito APIs"
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Load Balancer for Jito APIs | Backpac</title>
        <meta
          name="description"
          content={
            "Leverage multiple Jito endpoints via a single RPC endpoint. Interact seamlessly with both Solana and Jito for unparalleled speed, reliability, and scalability."
          }
        />
        <meta
          name="keywords"
          content="Jito API, Solana, Load Balancer, RPC Endpoint, Blockchain, MEV Protection, Backpac"
        />

        <meta
          property="og:title"
          content="Seamless Load Balancing for Jito APIs | Backpac"
        />
        <meta
          property="og:description"
          content="Effortlessly interact with Solana and Jito using a single, unified RPC endpoint. Achieve unmatched performance with Backpac's Jito API Load Balancer."
        />
        <meta
          property="og:url"
          content="https://backpac.xyz/jito-load-balancer"
        />
        <meta
          name="twitter:title"
          content="Seamless Load Balancing for Jito APIs | Backpac"
        />
        <meta
          name="twitter:description"
          content="Interact seamlessly with Solana and Jito through a single RPC endpoint. Leverage multiple Jito endpoints for unparalleled speed and reliability."
        />
        <meta name="twitter:image" content="https://image.nft.backpac.xyz/ICON_WHITE_1024_1024.jpg" />
        <meta
          name="twitter:image:alt"
          content="Seamless Load Balancing for Jito APIs | Backpac"
        />
        <meta
          property="og:image:alt"
          content="Seamless Load Balancing for Jito APIs | Backpac"
        />

        <link
          rel="canonical"
          href="https://backpac.xyz/jito-load-balancer"
        />
        <script type="application/ld+json">
          {JSON.stringify(jsonLdSchema)}
        </script>
      </Helmet>
      <Hero />
      <Benefits />
      <Plus />
      <HowToSection />
    </>
  );
};
export default Jito;
