import { useAuth0 } from '@auth0/auth0-react';

export default function FeaturesCompare() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return (
    <div className="bg-white dark:bg-neutral-900 py-12 sm:py-16">

      <div className="mx-auto max-w-7xl px-6 lg:px-8 text-gray-600 dark:text-white">
        <div className="flex items-center h-20 px-4 border-b border-gray-500">
            <div className="w-40"></div>
            <div className="flex-grow text-lg font-semibold text-center">VELOCITY</div>
            <div className="flex-grow text-lg font-semibold text-center">THROUGHPUT</div>
            <div className="flex-grow text-lg font-semibold text-center">HIGH THROUGHPUT</div>
            <div className="flex-grow text-lg font-semibold text-center">ENTERPRISE</div>
        </div>
        {/* Feature Group */}
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Request (Supported by Backpac RPC Endpoints)</div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">75M (then $4/M)</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">150M (then $4/M)</div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">300M (then $3/M)</div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">1B (then $2/M)</div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Request Rate per second (*up to)</div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">*250 Request/sec</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">*500 Request/sec</div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">*1,000 Request/sec</div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex-grow w-0"></div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">5,000+ Request/sec</div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex-grow w-0"></div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Region Distribution</div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Global Regions</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        {/* Feature Group */}
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Custom Load Balancer Listener Rules (routing strategy)</div>
        </div>
        
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">RPC Method</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">HTTP Header</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Source IP Address</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        {/* Feature Group */}
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Custom Load Balancer Target Groups (multiple RPC Endpoints)</div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Backpac Endpoints</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Custom Endpoints</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
                {/* Feature Group */}
        <div className="flex items-center h-12 px-4 bg-gray-100 border-b border-gray-500 text-gray-600 dark:black">
            <div className="font-medium">Intelligent Routing & Caching</div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Block Height</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
        <div className="flex items-center h-12 px-4 border-b border-gray-500">
            <div className="w-40">Smart Caching</div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
            <div className="flex justify-center flex-grow w-0">
                <svg className="w-4 h-4 text-green-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd" />
                </svg>
            </div>
        </div>
              <div className="flex items-center h-20 px-4">
                  <div className="w-40"></div>
                  <div className="flex items-center flex-grow w-0 px-8">
                      <button
                          onClick={() => {
                              sessionStorage.setItem('plan', 'velocity');
                              //Log them into the app
                              !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
                              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                          }}
                          className="flex items-center justify-center w-full h-8 text-sm text-white bg-black">
                          Get Velocity</button>
                  </div>
                  <div className="flex items-center flex-grow w-0 px-8">
                      <button
                          onClick={() => {
                              sessionStorage.setItem('plan', 'throughput');
                              //Log them into the app
                              !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
                              //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                          }}
                          className="flex items-center justify-center w-full h-8 text-sm text-white bg-black">
                          Get Throughput</button>
                  </div>
                  <div className="flex items-center flex-grow w-0 px-8">
                      <button onClick={() => {
                          sessionStorage.setItem('plan', 'high-throughput');
                          //Log them into the app
                          !isAuthenticated ? loginWithRedirect() : document.location.href = "/dashboard";
                          //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                      }} className="flex items-center justify-center w-full h-8 text-sm text-white bg-black">
                          Get High Throughput</button>
                  </div>
                  <div className="flex items-center flex-grow w-0 px-8">
                      <button onClick={() => {
                          sessionStorage.setItem('plan', 'enterprise');
                          window.open(
                              "https://meetings.hubspot.com/allyn-alford",
                              "_blank"
                          );
                          //document.location.href = "/login?plan=" + pricing.metadata['plan'];
                      }} className="flex items-center justify-center w-full h-8 text-sm text-white bg-black">
                          Get Enterprise</button>
                  </div>
              </div>
    </div>
    </div>
  )
}
